<template>
  <div class="body">
    <div class="content">
      <div class="title">
        <span>模拟器配置</span>
      </div>
      <div style="margin: 20px"></div>
      <div class="list">
        <el-form label-width="130px" size="mini">
          <el-form-item label="前台网站域名" :required="true">
            <el-input size="medium" v-model="viewUrl"></el-input>
          </el-form-item>
        </el-form>
        <div style="font-size: 14px; color: #999">提示:请输入用户端展示的域名，用于DIY的网站访问</div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <span>阿里云oss</span>
      </div>
      <div style="margin: 20px"></div>
      <div style="font-size: 14px; color: #999">提示:需进入oss控制面板 - 设置跨域 来源：* 允许methods：全选 允许headers：* 暴露headers:Etag</div>
      <div style="margin: 20px"></div>
      <div class="list">
        <el-form label-width="130px" size="mini">
          <el-form-item label="阿里云oss配置（region）" :required="true">
            <el-input size="medium" v-model="oss.region" placeholder="阿里云oss配置 yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。"></el-input>
          </el-form-item>
          <el-form-item label="阿里云oss配置（accessKeyId）" :required="true">
            <el-input size="medium" v-model="oss.accessKeyId" placeholder=" 填写AccessKey ID"></el-input>
          </el-form-item>
          <el-form-item label="阿里云oss配置（accessKeySecret）" :required="true">
            <el-input size="medium" v-model="oss.accessKeySecret" placeholder=" 填写AccessKey Secret"></el-input>
          </el-form-item>
          <el-form-item label="阿里云oss配置（bucket）" :required="true">
            <el-input size="medium" v-model="oss.bucket" placeholder=" 填写Bucket名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-button type="primary" style="margin-left: 120px; width: 200px" @click="submit">保存</el-button>
  </div>
</template>

<script>
import http from '@/utils/cloud'
import global from '@/config/global'

export default {
  data() {
    return {
      viewUrl: '',
      oss: {
        region: '',
        accessKeyId: '',
        accessKeySecret: '',
        bucket: ''
      }
    }
  },
  created() {
    this.getText()
  },
  methods: {
    getText() {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSystem'
        })
        .then(res => {
          if (res) {
            this.oss = res.oss
            this.viewUrl = res.viewUrl
          }
        })
    },
    submit() {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'updateSystem',
          oss: this.oss,
          viewUrl: this.viewUrl
        })
        .then(res => {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;
  padding: 15px;

  .content {
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    ::v-deep .el-input--medium {
      width: 300px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      padding-left: 12px;
      border-left: 6px solid #6775e1;
    }
  }
}
</style>
